<template>
  <div class="field-item">
    <div class="field-item__label">
      {{ itemVal.label }}
    </div>
    <textInput
      :typeInput="'text'"
      :value.sync="formatDate"
      :showModal="true"
      :textCapitalize="true"
      :readonly="true"
      :class="{'active': datePicker}"
      :calendar="true"
      @handleClickIcon="showCalendar"
    >
    </textInput>
    <transition name="fade-el">
      <datePickerModal
        v-if="datePicker"
        :yearSettings="true"
        :date.sync="itemVal.value"
        :datePicker.sync="datePicker"
        :style="popupPosition"
        @closeModal="closeModal"
      >
      </datePickerModal>
    </transition>
  </div>
</template>

<script>
import textInput from '@/components/fields/textInput';
import moment from 'moment';
import datePickerModal from '@/components/modals/datePickerModal';

export default {
  name: 'DateField',
  components: {
    textInput,
    datePickerModal,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupPosition: {},
      datePicker: false,
    };
  },
  computed: {
    formatDate() {
      if (this.itemVal.value) {
        return moment(this.itemVal.value).locale(this.language).format('MMMM DD, YYYY');
      }
      return '';
    },
    language() {
      return this.$i18n.locale;
    },
    itemVal: {
      get() {
        return this.item;
      },
      set(data) {
        this.$emit('update:item', data);
      },
    },
  },
  methods: {
    closeModal() {
      this.datePicker = false;
    },
    showCalendar(e) {
      this.datePicker = !this.datePicker;
      if (e) {
        const position = e.target.getBoundingClientRect();
        if (position) {
          const { top, left } = position;
          this.popupPosition = {
            position: 'fixed',
            top: `${top - 275}px`,
            right: 'auto',
            left: `${left + 35}px`,
          };
        } else {
          this.popupPosition = {};
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../sass/_variables.scss";
@import "../../sass/fields";
</style>
